import { Factory } from "../..";

export enum CardTransactionErrorType {
    InsufficientFunds = 'InsufficientFunds',
    Declined = 'Declined',
    Unspecified = 'Unspecified',
  }
  
  export class CardTransactionError {
    type: CardTransactionErrorType;
    text?: string;
  
    constructor(json: any) {
      this.type = json.type;
      this.text = json.text ? json.text : undefined;
    }
  
    public static getFactory(): Factory<CardTransactionError> {
      return new (class implements Factory<CardTransactionError> {
        make(json: any): CardTransactionError {
          switch (json.type) {
            case CardTransactionErrorType.InsufficientFunds:
              return new CardTransactionError(json);
            case CardTransactionErrorType.Declined:
              return new CardTransactionError(json);
            case CardTransactionErrorType.Unspecified:
              return new CardTransactionError(json);
            default:
              throw new Error('Unrecognized CardTransactionError type (' + json.type + ').');
          }
        }
  
        getTableName(): string {
          throw new Error('getTableName() should never be called on a CardTransactionError.');
        }
      })();
    }
  }