import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  Currency,
  Discount,
  DiscountPercentageVoucher,
  DiscountSinglePeriodPercentageVoucher,
  DiscountType,
  Period,
  SinglePeriodPriceCampaign,
  Links,
  UserProductTemplate,
  ObjectProductTemplate,
  ProductTemplate,
  Cost
} from '../../models';
import { CacheService } from '../cache/cache.service';
import Decimal from 'decimal.js';
import { Utilities } from 'src/app/models/class/utilities';
import { RegionService } from '../region/region.service';
import { ErrorService } from 'src/app/shared/error/error.service';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  private display$ = new BehaviorSubject(Display.Display);
  display = this.display$.asObservable();

  private price$ = new BehaviorSubject(new Filter());
  price = this.price$.asObservable();

  private activeTemplate: UserProductTemplate | undefined;
  private activeDiscount: Discount | undefined;

  constructor(
    private cache: CacheService,
    private regionService: RegionService,
    private errorService: ErrorService,
  ) {}

  show() {
    this.display$.next(Display.Display);
  }

  hide() {
    this.display$.next(Display.None);
  }

  getNetPremiumForFamilyProduct(cost: Cost, currency: Currency): { amount: string; intermediaryAmount: string | undefined } {
    const netPremium = () => {
      if (!cost.netPremium) {
        return new Decimal(0);
      } else {
        return new Decimal(cost.netPremium).mul(1);
      }
    };

    const intermediaryAmount = netPremium()
      ? new Decimal(cost.price)
          .sub(netPremium())
          .toFixed(Utilities.getScale(currency), Decimal.ROUND_HALF_EVEN)
      : undefined;

    return {
      amount: netPremium().toFixed(Utilities.getScale(currency), Decimal.ROUND_HALF_EVEN),
      intermediaryAmount: intermediaryAmount,
    };
  }

  setPriceFromActiveTemplate(period: Period) {
    if (this.activeTemplate) {
      this.activeTemplate.versions[this.activeTemplate.versions.length - 1].costs.forEach((cost) => {
        if (cost.schedule === period) {
          if (cost.discount && cost.discount instanceof SinglePeriodPriceCampaign) {
            const discountPrice = new Decimal(cost.discount.price).toFixed(
              Utilities.getScale(this.activeTemplate!.currency),
              Decimal.ROUND_HALF_EVEN,
            );

            const deductedAmount = new Decimal(discountPrice)
              .sub(cost.price)
              .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN);

            const netPremium = () => {
              if (!cost.netPremium) {
                return new Decimal(0);
              } else {
                return new Decimal(cost.netPremium).mul(1);
              }
            };

            const netPremiumAmount = netPremium()
              ? new Decimal(discountPrice)
                  .sub(netPremium())
                  .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN)
              : undefined;

            this.price$.next(
              new Filter(
                new Decimal(cost.price).toFixed(
                  Utilities.getScale(this.activeTemplate!.currency),
                  Decimal.ROUND_HALF_EVEN,
                ),
                discountPrice,
                this.activeTemplate!.currency,
                cost.schedule,
                deductedAmount.toString(),
                cost.discount.texts
                  ? Utilities.parseI18n(cost.discount.texts, this.regionService.getClientLanguage())
                  : 'KAMPANJE',
                netPremium().toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN),
                netPremiumAmount,
                new Decimal(cost.price).toFixed(0),
                new Decimal(cost.discount.price).toFixed(0),
              ),
            );

            return;
          } else {
            const price = new Decimal(cost.price).toFixed(
              Utilities.getScale(this.activeTemplate!.currency),
              Decimal.ROUND_HALF_EVEN,
            );

            const netPremium = () => {
              if (!cost.netPremium) {
                return new Decimal(0);
              } else {
                return new Decimal(cost.netPremium).mul(1);
              }
            };

            const yearlyAmount = () => {
              if (!cost.price) {
                return new Decimal(0);
              } else {
                return new Decimal(cost.price).mul(1);
              }
            };

            const netPremiumAmount = netPremium()
              ? new Decimal(cost.price)
                  .sub(netPremium())
                  .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN)
              : undefined;

            this.price$.next(
              new Filter(
                price,
                undefined,
                this.activeTemplate!.currency,
                cost.schedule,
                undefined,
                undefined,
                netPremium().toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN),
                netPremiumAmount,
                new Decimal(cost.price).toFixed(0),
                undefined,
              ),
            );

            return;
          }
        }
      });
    }
  }

  setPriceFromActiveDiscountAndDiscountTemplate(period: Period) {
    if (!this.activeDiscount || !this.activeTemplate) {
      return;
    }

    switch (this.activeDiscount.type) {
      case DiscountType.PercentageVoucher:
        let discountPercentageVoucher = this.activeDiscount as DiscountPercentageVoucher;
        this.activeTemplate.versions[this.activeTemplate.versions.length - 1].costs.forEach((cost) => {
          if (cost.schedule === period) {
            const discountPrice = new Decimal(cost.price)
              .mul(new Decimal(1).sub(new Decimal(discountPercentageVoucher.percent)))
              .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN);

            const deductedAmount = new Decimal(discountPrice)
              .sub(cost.price)
              .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN);

            const netPremium = () => {
              if (!cost.netPremium) {
                return new Decimal(0);
              } else {
                return new Decimal(cost.netPremium).mul(1);
              }
            };

            const netPremiumAmount = netPremium()
              ? new Decimal(discountPrice)
                  .sub(netPremium())
                  .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN)
              : undefined;

            this.price$.next(
              new Filter(
                new Decimal(cost.price).toFixed(
                  Utilities.getScale(this.activeTemplate!.currency),
                  Decimal.ROUND_HALF_EVEN,
                ),
                discountPrice,
                this.activeTemplate!.currency,
                cost.schedule,
                deductedAmount,
                'KAMPANJE',
                netPremium().toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN),
                netPremiumAmount,
                new Decimal(cost.price).toFixed(0),
                discountPrice,
              ),
            );
            return;
          }
        });
        break;

      case DiscountType.SinglePeriodPercentageVoucher:
        let discountSinglePeriodPercentageVoucher = this.activeDiscount as DiscountSinglePeriodPercentageVoucher;
        this.activeTemplate.versions[this.activeTemplate.versions.length - 1].costs.forEach((cost) => {
          if (cost.schedule === period) {
            const discountPrice = new Decimal(cost.price)
              .mul(new Decimal(1).sub(new Decimal(discountSinglePeriodPercentageVoucher.percent)))
              .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN);

            const deductedAmount = new Decimal(discountPrice)
              .sub(cost.price)
              .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN);

            const netPremium = () => {
              if (!cost.netPremium) {
                return new Decimal(0);
              } else {
                return new Decimal(cost.netPremium).mul(1);
              }
            };

            const netPremiumAmount = netPremium()
              ? new Decimal(discountPrice)
                  .sub(netPremium())
                  .toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN)
              : undefined;

            this.price$.next(
              new Filter(
                new Decimal(cost.price).toFixed(
                  Utilities.getScale(this.activeTemplate!.currency),
                  Decimal.ROUND_HALF_EVEN,
                ),
                discountPrice,
                this.activeTemplate!.currency,
                cost.schedule,
                deductedAmount,
                'KAMPANJE',
                netPremium().toFixed(Utilities.getScale(this.activeTemplate!.currency), Decimal.ROUND_HALF_EVEN),
                netPremiumAmount,
                new Decimal(cost.price).toFixed(0),
                discountPrice,
              ),
            );
            return;
          }
        });
        break;
      case DiscountType.SinglePeriodPriceCampaign:
        break;
      default:
        throw new Error('Unrecognized DiscountTemplate type (' + this.activeDiscount.type + ').');
    }
  }

  clearPrice() {
    this.price$.next(new Filter());
  }

  setActiveDiscount(discount: Discount) {
    this.activeDiscount = discount;
  }

  setActiveTemplate(template: UserProductTemplate) {
    localStorage.setItem('activeTemplateId', template.id);
    this.activeTemplate = template;
  }

  async getLinksFromTemplate(): Promise<Links> {
    const pt = await this.getActiveTemplate();

    if (pt instanceof UserProductTemplate) {
      this.activeTemplate = pt;
    }

    const latestVersion = this.activeTemplate?.getLatestVersion();

    if (this.activeTemplate) {
      return {
        url: latestVersion?.url,
        termsUrl: latestVersion?.termsUrl,
        prePurchaseUrl: latestVersion?.prePurchaseInformationUrl,
        productSheetUrl: latestVersion?.productSheetUrl,
      };
    } else {
      return {
        url: '',
        termsUrl: '',
        prePurchaseUrl: '',
        productSheetUrl: '',
      };
    }
  }

  async setActiveTemplateFromId(id: string): Promise<ProductTemplate | void> {
    try {
      const template = await this.cache.getProductTemplate(id);

      if (template instanceof UserProductTemplate) {
        this.setActiveTemplate(template);
        return template;
      } else if (template instanceof ObjectProductTemplate) {
        return this.errorService.setError(
          "In this shop, we don't support object insurnces. Contact support for more information." + template.id,
        );
      }
    } catch (error) {
      this.errorService.setError(
        'Could not find and set the product template with id: ' + id + '. Contact support for more information.',
      );
    }
  }

  async getActiveTemplate(): Promise<ProductTemplate | undefined> {
    if (this.activeTemplate) {
      return this.activeTemplate;
    } else {
      const id = localStorage.getItem('activeTemplateId');
      if (id) {
        await this.setActiveTemplateFromId(id);
        return this.activeTemplate;
      } else {
        return undefined;
      }
    }
  }

  clearActiveTemplate() {
    localStorage.removeItem('activeTemplateId');
    this.clearActiveDiscount();
    this.activeTemplate = undefined;
  }

  async getActiveDiscount(): Promise<Discount | undefined> {
    return this.activeDiscount;
  }

  clearActiveDiscount() {
    this.activeDiscount = undefined;
  }

  i18nPeriod(period?: Period, currency?: Currency): string {
    switch (`${period}-${currency}`) {
      case `${Period.Month}-${Currency.NOK}`:
        return 'måned';
      case `${Period.QuarterYear}-${Currency.NOK}`:
        return 'kvartal';
      case `${Period.HalfYear}-${Currency.NOK}`:
        return 'halvår';
      case `${Period.Year}-${Currency.NOK}`:
        return 'år';
      case `${Period.Month}-${Currency.SEK}`:
        return 'månad';
      case `${Period.QuarterYear}-${Currency.SEK}`:
        return 'kvartal';
      case `${Period.HalfYear}-${Currency.SEK}`:
        return 'halvår';
      case `${Period.Year}-${Currency.SEK}`:
        return 'år';
      default:
        return '';
    }
  }
}

export enum Display {
  None = 'None',
  Display = 'Display',
}

export class Filter {
  originalAmount?: string;
  discountAmount?: string;
  currency?: Currency;
  period?: Period;
  deductedAmount?: string;
  campaignTitle?: string;
  netPremium?: string;
  intermediaryAmount?: string;
  originalAmountWithoutDecimals?: string;
  discountAmountWithoutDecimals?: string;
  yearlyAmount?: string;

  constructor(
    originalAmount?: string,
    discountAmount?: string,
    currency?: Currency,
    period?: Period,
    deductedAmount?: string,
    campaignTitle?: string,
    netPremium?: string,
    intermediaryAmount?: string,
    originalAmountWithoutDecimals?: string,
    discountAmountWithoutDecimals?: string,
  ) {
    this.originalAmount = originalAmount;
    this.discountAmount = discountAmount;
    this.currency = currency;
    this.period = period;
    this.deductedAmount = deductedAmount;
    this.campaignTitle = campaignTitle;
    this.netPremium = netPremium;
    this.intermediaryAmount = intermediaryAmount;
    this.originalAmountWithoutDecimals = originalAmountWithoutDecimals;
    this.discountAmountWithoutDecimals = discountAmountWithoutDecimals;
  }
}
