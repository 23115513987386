import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    const skeleton = document.getElementById('skeleton');
    if (skeleton) {
      skeleton.remove(); // Remove the skeleton after Angular has bootstrapped
    }
  })
  .catch((err) => console.error(err));
