interface IndexConfig {
  name: string;
  keyPath: string;
  options?: IDBIndexParameters;
}

interface ObjectStoreConfig {
  name: string;
  keyPath: string;
  version: number; // The version at which this store was added
  indexes?: IndexConfig[];
}

export const OBJECT_STORE_CONFIGS: ObjectStoreConfig[] = [
  {
    name: 'users',
    keyPath: 'id',
    version: 1,
  },
  {
    name: 'organizations',
    keyPath: 'id',
    version: 1,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
  {
    name: 'stories',
    keyPath: 'id',
    version: 1,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
  {
    name: 'vehicle_brands',
    keyPath: 'id',
    version: 1,
  },
  {
    name: 'vehicle_models',
    keyPath: 'id',
    version: 1,
    indexes: [{ name: 'vehicleBrandId', keyPath: 'vehicleBrandId', options: { unique: false } }],
  },
  {
    name: 'product_templates',
    keyPath: 'id',
    version: 1,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
  {
    name: 'products',
    keyPath: 'id',
    version: 1,
    indexes: [
      { name: 'countryCode', keyPath: 'countryCode', options: { unique: false } },
      { name: 'templateId', keyPath: 'templateId', options: { unique: false } },
      { name: 'userId', keyPath: 'userId', options: { unique: false } },
    ],
  },
  {
    name: 'payment_methods',
    keyPath: 'id',
    version: 3,
    indexes: [
      { name: 'id', keyPath: 'id', options: { unique: false } },
      { name: 'userId', keyPath: 'userId', options: { unique: false } },
    ],
  },
  {
    name: 'payments',
    keyPath: 'id',
    version: 4,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
  {
    name: 'objects',
    keyPath: 'id',
    version: 4,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
  {
    name: 'discounts',
    keyPath: 'id',
    version: 7,
    indexes: [{ name: 'countryCode', keyPath: 'countryCode', options: { unique: false } }],
  },
];
