import { Factory } from "../..";

export enum ReminderChannelType {
    Email = 'Email',
    Phone = 'Phone',
  }
  
  export class ReminderChannel {
    type: ReminderChannelType;
  
    constructor(json: any) {
      this.type = json.type;
    }
  
    public static getFactory(): Factory<ReminderChannel> {
      return new (class implements Factory<ReminderChannel> {
        make(json: any): ReminderChannel {
          switch (json.type) {
            case ReminderChannelType.Email:
              return new EmailReminderChannel(json);
            case ReminderChannelType.Phone:
              return new PhoneReminderChannel(json);
            default:
              throw new Error('Unrecognized ReminderChannel type (' + json.type + ').');
          }
        }
  
        getTableName(): string {
          throw new Error('getTableName() should never be called on a ReminderChannel.');
        }
      })();
    }
  }
  
  export class EmailReminderChannel extends ReminderChannel {
    address: string;
  
    constructor(json: any) {
      super(json);
      this.address = json.address;
    }
  }
  
  export class PhoneReminderChannel extends ReminderChannel {
    number: string;
  
    constructor(json: any) {
      super(json);
      this.number = json.number;
    }
  }
  
  
  export enum Status {
    Unpaid = 'Unpaid',
    PartlyPaid = 'PartlyPaid',
    PaidInFull = 'PaidInFull',
    Overpaid = 'Overpaid', 
  }