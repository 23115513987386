import { CountryCode } from '../enum/country-code';
import { Currency } from '../enum/currency';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { Identifiable } from '../interface/identifiable';
import { MothershipData } from './mothership-data';
import { Owner } from './owner';
import {
  AvtaleGiroIssued,
  CardTransactionFailed,
  CardTransactionSucceeded,
  CreditNoteIssued,
  InvoiceIssued,
  PaymentEvent as PaymentEvent,
} from './payment/payment-events';
import { Status } from './payment/payment-reminder-channel';
import { ObjectProductTemplate, UserProductTemplate } from './product/product-template';

export interface PaymentUi {
  actual: Payment;
  template: UserProductTemplate | ObjectProductTemplate;
}

export enum PaymentType {
  V2 = 'V2',
}

export class Payment implements Identifiable, Deletable {
  id: string;
  type: PaymentType;

  // NOTE: Lower index is higher in the hierarchy. Denormalized from product.
  issuingOrganizationIds: string[];

  countryCode: CountryCode;
  owner: Owner;

  currency: Currency;
  balance: number;
  status: Status;
  history: PaymentEvent[];
  mothershipData?: MothershipData;

  created: Date;
  modified: Date;
  deleted?: boolean;

  constructor(json: any, type: PaymentType) {
    this.id = json.id;
    this.type = type;

    this.issuingOrganizationIds = json.issuingOrganizationIds ? json.issuingOrganizationIds : [];

    this.countryCode = json.countryCode as CountryCode;

    const ownerFactory = Owner.getFactory();
    this.owner = ownerFactory.make(json.owner);
    this.balance = json.balance;
    this.status = json.status;
    this.history = json.history;
    this.mothershipData = json.mothershipData ? json.mothershipData : undefined;

    this.currency = json.currency as Currency;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }
  getLast() {
    if (this.history !== undefined) {
      for (let i = this.history.length - 1; i >= 0; i--) {
        const event = PaymentEvent.getFactory().make(this.history[i]);

        if (
          event instanceof CardTransactionSucceeded ||
          event instanceof CardTransactionFailed ||
          event instanceof InvoiceIssued ||
          event instanceof AvtaleGiroIssued
        ) {
          return event;
        }
      }
    }

    throw new Error('No payment event found. Payment ID: ' + this.id);
  }

  getPaymentEventType() {
    return this.getLast().type;
  }

  /**
   * Checks if the total CreditNoteIssued amounts equal the specified amount.
   * @param amount The amount to match against CreditNoteIssued events.
   * @returns {boolean} True if total credit notes equal the amount, else false.
   */
  hasCreditNotesForAmount(amount: number): boolean {
    let creditAmounts = 0;
    this.history.forEach((eventJson) => {
      const event = PaymentEvent.getFactory().make(eventJson);
      if (event instanceof CreditNoteIssued) {
        creditAmounts += event.amount;
      }
    });

    return creditAmounts === amount;
  }

  /**
   * Determines if the payment is unpaid.
   * @returns {boolean} True if the payment is unpaid, else false.
   */
  isUnpaid(): boolean {
    return this.status === Status.Unpaid;
  }

  /**
   * Determines if the payment is partly paid.
   * @returns {boolean} True if the payment is partly paid, else false.
   */
  isPartlyPaid(): boolean {
    return this.status === Status.PartlyPaid;
  }

  /**
   * Retrieves the last relevant payment event (InvoiceIssued, AvtaleGiroIssued, CardTransactionSucceeded).
   * @returns {PaymentEvent | undefined} The last relevant payment event or undefined if not found.
   */
  getLastRelevantPaymentEvent(): PaymentEvent | undefined {
    if (!this.history) {
      return;
    }

    for (let i = this.history.length - 1; i >= 0; i--) {
      let event: PaymentEvent;

      try {
        event = PaymentEvent.getFactory().make(this.history[i]);
      } catch (error) {
        console.error(`Error creating PaymentEvent for payment ID ${this.id}:`, error);
        continue; // Skip invalid events
      }

      if (
        event instanceof CardTransactionSucceeded ||
        event instanceof AvtaleGiroIssued ||
        event instanceof InvoiceIssued
      ) {
        return event;
      }
    }

    return undefined;
  }

  getDueDate(): Date | undefined {
    const event = this.getLastRelevantPaymentEvent();
    if (event instanceof InvoiceIssued) {
      return event.dueDate;
    } else if (event instanceof AvtaleGiroIssued) {
      return event.dueDate;
    } else {
      return undefined;
    }
  }

  // ... other methods

  public static getFactory(): Factory<Payment> {
    return new (class implements Factory<Payment> {
      make(json: any): Payment {
        return new Payment(json, json.type);
      }

      getTableName(): string {
        return 'payments';
      }
    })();
  }

  static getUrl(paymentId?: string): string;
  static getUrl(paymentId: string): string {
    return '/payments' + (paymentId ? '/' + paymentId : '');
  }
}

export enum CardTransactionStatus {
  Authorized = 'Authorized', // Reserverad
  Captured = 'Captured', // Betald
}

export enum FailedAttemptType {
  Card = 'Card',
}

export class FailedAttempt {
  type: FailedAttemptType;

  constructor(json: any, type: FailedAttemptType) {
    this.type = type;
  }

  public static getFactory(): Factory<FailedAttempt> {
    return new (class implements Factory<FailedAttempt> {
      make(json: any): FailedAttempt {
        switch (json.type) {
          case FailedAttemptType.Card:
            return new CardFailedAttempt(json);
          default:
            throw new Error('Unrecognized FailedAttempt type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on a FailedAttempt. FailedAttempts are not stored in IndexedDB.',
        );
      }
    })();
  }
}

export class CardFailedAttempt extends FailedAttempt {
  paymentError: PaymentError;
  amount: number; // NOTE: After discount.
  paymentMethodId: string;
  discountId?: string;
  verificationUrl?: string;
  from: Date;
  to: Date;
  at: Date; // Time of payment attempt.

  constructor(json: any) {
    super(json, FailedAttemptType.Card);
    this.paymentError = new PaymentError(json.paymentError);
    this.amount = Number(json.amount);
    this.paymentMethodId = json.paymentMethodId;
    this.discountId = json.discountId ? json.discountId : undefined;
    this.verificationUrl = json.verificationUrl ? json.verificationUrl : undefined;
    this.from = new Date(json.from);
    this.to = new Date(json.to);
    this.at = new Date(json.at);
  }
}

export enum PaymentErrorType {
  Unspecified = 'Unspecified',
}

export class PaymentError {
  type: PaymentErrorType;
  reason: string;
  constructor(reason: string) {
    this.type = PaymentErrorType.Unspecified;
    this.reason = reason;
  }
}
