import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { MothershipData } from './mothership-data';
import { Currency } from '../enum/currency';

export enum PaymentSpecificationType {
  V2 = 'V2',
}

export class PaymentSpecification implements Identifiable {
  type: PaymentSpecificationType;
  id: string;

  paymentId?: string;

  amount: number; // NOTE: After discount.
  currency: Currency;
  from: Date;
  to: Date;

  mothershipData?: MothershipData;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.type = json.type;
    this.id = json.id;

    this.paymentId = json.paymentId ? json.paymentId : undefined;

    this.amount = Number(json.amount);
    this.currency = json.currency as Currency;
    this.from = new Date(json.from);
    this.to = new Date(json.to);

    this.mothershipData = json.mothershipData ? new MothershipData(json.mothershipData) : undefined;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<PaymentSpecification> {
    return new (class implements Factory<PaymentSpecification> {
      make(json: any): PaymentSpecification {
       return new PaymentSpecification(json);
      }

      getTableName(): string {
        throw new Error('Payment specifications are not stored in IndexedDB.');
      }
    })();
  }
}
