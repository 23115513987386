import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { CountryCode } from '../enum/country-code';
import { I18nString } from './i18nstring';
import { HasCountryCode } from '../interface/has-country-code';

import { Period } from '../enum/period';
import { DiscountEligibility } from './discount-eligability';

export enum DiscountTemplateType {
  PercentageVoucher = 'PercentageVoucher',
  SinglePeriodPercentageVoucher = 'SinglePeriodPercentageVoucher',
  SinglePeriodPriceCampaign = 'SinglePeriodPriceCampaign',
}

export class DiscountTemplate {
  type: DiscountTemplateType;
  texts?: I18nString[];
  expiry?: Date;

  constructor(json: any, type: DiscountTemplateType) {
    this.type = type;
    this.texts = json.texts ? json.texts.map((js: any) => new I18nString(js)) : undefined;
    this.expiry = json.expiry ? new Date(json.expiry) : undefined;
  }

  public static getFactory(): Factory<DiscountTemplate> {
    return new (class implements Factory<DiscountTemplate> {
      make(json: any): DiscountTemplate {
        switch (json.type) {
          case DiscountTemplateType.PercentageVoucher:
            return new PercentageVoucher(json);
          case DiscountTemplateType.SinglePeriodPercentageVoucher:
            return new SinglePeriodPercentageVoucher(json);
          case DiscountTemplateType.SinglePeriodPriceCampaign:
            return new SinglePeriodPriceCampaign(json);
          default:
            throw new Error('Unrecognized DiscountTemplate type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'discount_templates';
      }
    })();
  }

  static getUrl(countryCode: CountryCode, templateId?: string): string;
  static getUrl(countryCode: CountryCode, templateId: string): string {
    return '/countries/' + countryCode + '/discount_templates' + (templateId ? '/' + templateId : '');
  }
}

// Claimable, redeemable and applies for all payments until expiry.
export class PercentageVoucher extends DiscountTemplate implements Identifiable, Deletable, HasCountryCode {
  id: string;
  deleted: boolean;

  countryCode: CountryCode;

  code: string; // NOTE: When a template is deleted, the code is set to template id.
  readonly originalCode: string;
  percent: number;
  max?: number;
  claimed: number;
  redeemed: number;
  eligability: DiscountEligibility[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    super(json, DiscountTemplateType.PercentageVoucher);
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.countryCode = json.countryCode as CountryCode;

    this.code = json.code;
    this.originalCode = json.originalCode;
    this.percent = Number(json.percent);
    this.max = json.max !== undefined ? Number(json.max) : undefined;
    this.claimed = Number(json.claimed);
    this.redeemed = Number(json.redeemed);
    const ef: Factory<DiscountEligibility> = DiscountEligibility.getFactory();
    this.eligability = json.eligability ? json.eligability.map((js: any) => ef.make(js)) : [];

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }
}

// Claimable and redeemable until expiry. Applies for payments in one period only.
export class SinglePeriodPercentageVoucher extends DiscountTemplate implements Identifiable, Deletable, HasCountryCode {
  id: string;
  deleted: boolean;

  countryCode: CountryCode;

  code: string; // NOTE: When a template is deleted, the code is set to template id.
  readonly originalCode: string;
  percent: number;
  period: Period;
  max?: number;
  claimed: number;
  redeemed: number;
  eligability: DiscountEligibility[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    super(json, DiscountTemplateType.SinglePeriodPercentageVoucher);
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.countryCode = json.countryCode as CountryCode;

    this.code = json.code;
    this.originalCode = json.originalCode;
    this.percent = Number(json.percent);
    this.period = json.period as Period;
    this.max = json.max !== undefined ? Number(json.max) : undefined;
    this.claimed = Number(json.claimed);
    this.redeemed = Number(json.redeemed);
    const ef: Factory<DiscountEligibility> = DiscountEligibility.getFactory();
    this.eligability = json.eligability ? json.eligability.map((js: any) => ef.make(js)) : [];

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }
}

// For use in product template costs.
// NOTE: Redeemable until expiry.
export class SinglePeriodPriceCampaign extends DiscountTemplate {
  price: number;
  constructor(json: any) {
    super(json, DiscountTemplateType.SinglePeriodPriceCampaign);
    this.price = Number(json.price);
  }
}
