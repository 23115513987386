import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SjekkpunktFormComponent } from './sjekkpunkt/sjekkpunkt-form/sjekkpunkt-form.component';
import { DownloadAppComponent } from './download-app/download-app.component';

const routes: Routes = [
  // {
  //   path: 'sign-in',
  //   loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
  // },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'filer',
    loadChildren: () => import('./files/files.module').then((m) => m.FilesModule),
  },

  {
    path: 'my-pages',
    loadChildren: () => import('./my-pages/my-pages.module').then((m) => m.MyPagesModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'sjekkpunkt',
    pathMatch: 'full',
    component: SjekkpunktFormComponent,
  },
  {
    path: 'app',
    pathMatch: 'full',
    component: DownloadAppComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
