import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { CardBrand } from '../enum/card-brand';
import { Owner } from './owner';

export enum PaymentServiceProvider {
  Worldline = 'Worldline',
}

export enum PaymentMethodCardType {
  NewCard = 'NewCard',
  Default = 'Default',
}

export enum PaymentMethodType {
  Card = 'Card',
  Invoice = 'Invoice',
  AvtaleGiro = 'AvtaleGiro',
}

export interface PaymentMethodUi {
  actual: PaymentMethod;
  card?: CardPaymentMethod;
  invoice?: InvoicePaymentMethod;
  avtaleGiro?: AvtaleGiroPaymentMethod;
  defaultPaymentMethod?: boolean;
}

export class PaymentMethod implements Deletable, Identifiable {
  type: PaymentMethodType;
  id: string;

  owner: Owner;
  organizationId?: string;

  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.
  created: Date;
  deleted: boolean;
  modified: Date;

  constructor(json: any, type: PaymentMethodType) {
    this.type = type;
    this.id = json.id;

    this.owner = Owner.getFactory().make(json.owner);
    this.organizationId = json.organizationId ? json.organizationId : undefined;

    this.deletedDbFlag = json.deleted ? 1 : 0;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<PaymentMethod> {
    return new (class implements Factory<PaymentMethod> {
      make(json: any): PaymentMethod {
        switch (json.type) {
          case PaymentMethodType.Card:
            return new CardPaymentMethod(json);
          case PaymentMethodType.Invoice:
            return new InvoicePaymentMethod(json);
          case PaymentMethodType.AvtaleGiro:
            return new AvtaleGiroPaymentMethod(json);
          default:
            throw new Error('Unrecognized PaymentMethod type (' + json.type + ').');
        }
      }
      getTableName(): string {
        return 'payment_methods';
      }
    })();
  }

  static getUrl(userId: string, paymentMethod: PaymentMethod): string {
    return `/users/${userId}/payment_methods/${paymentMethod.id}`;
  }
}

export class CardPaymentMethod extends PaymentMethod {
  brand: CardBrand;
  psp: PaymentServiceProvider;
  truncatedCardNumber: string;
  expiryMonth: number;
  expiryYear: number;
  token: string;
  note?: string;

  constructor(json: any) {
    super(json, PaymentMethodType.Card);
    this.brand = json.brand as CardBrand;
    this.psp = json.psp as PaymentServiceProvider;
    this.truncatedCardNumber = json.truncatedCardNumber;
    this.expiryMonth = json.expiryMonth;
    this.expiryYear = json.expiryYear;
    this.token = json.token;
    this.note = json.note ? json.note : undefined;
  }
}

export class InvoicePaymentMethod extends PaymentMethod {
  kid?: string;
  constructor(json: any) {
    super(json, PaymentMethodType.Invoice);
    this.kid = json.kid ? json.kid : undefined;
  }
}

export class AvtaleGiroPaymentMethod extends PaymentMethod {
  constructor(json: any) {
    super(json, PaymentMethodType.AvtaleGiro);
  }
}
