import { Factory } from "../..";

export enum CardTransactionStatusV2Type {
    Authorized = 'Authorized',
    Captured = 'Captured',
  }
  
  export class CardTransactionStatusV2 {
    type: CardTransactionStatusV2Type;
    created: Date;
  
    constructor(json: any) {
      this.type = json.type;
      this.created = new Date(json.created);
    }
  
    public static getFactory(): Factory<CardTransactionStatusV2> {
      return new (class implements Factory<CardTransactionStatusV2> {
        make(json: any): CardTransactionStatusV2 {
          switch (json.type) {
            case CardTransactionStatusV2Type.Authorized:
              return new CardTransactionStatusV2Authorization(json);
            case CardTransactionStatusV2Type.Captured:
              return new CardTransactionStatusV2Captured(json);
            default:
              throw new Error('Unrecognized CardTransactionStatusV2 type (' + json.type + ').');
          }
        }
  
        getTableName(): string {
          throw new Error('getTableName() should never be called on a CardTransactionStatusV2.');
        }
      })();
    }
  }
  
  export class CardTransactionStatusV2Authorization extends CardTransactionStatusV2 {
    constructor(json: any) {
      super(json);
    }
  }
  
  export class CardTransactionStatusV2Captured extends CardTransactionStatusV2 {
    constructor(json: any) {
      super(json);
    }
  }