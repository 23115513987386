import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytic.service';
import { FathomService } from '../services/fathom/fathom.service';

@Component({
  selector: 'app-download-app',
  standalone: true,
  imports: [],
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
  appleUrl: string = 'https://apps.apple.com/se/app/viking-assistance/id6449096742';
  androidUrl: string = 'https://play.google.com/store/apps/details?id=no.vikingredning.app';

  constructor(
    private router: Router,
    private ga4: GoogleAnalyticsService,
    private fathom: FathomService,
  ) {}

  async ngOnInit() {
    const userAgent = navigator.userAgent;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      await this.ga4.sendTypedEvent('redirect_to_apple_appstore', 'app_download', undefined, 'statistics');
      this.fathom.trackEvent(
      'redirect-to-apple-appstore',
      );
      window.location.href = this.appleUrl;
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
      await this.ga4.sendTypedEvent('redirect_to_google_playstore', 'app_download', undefined, 'statistics');
      this.fathom.trackEvent(
        `redirect-to-google-playstore`,
      );
      window.location.href = this.androidUrl;
    }
    // Default to navigate to home page if the device is neither iOS nor Android
    else {
      this.router.navigate(['/']);
    }
  }
}
