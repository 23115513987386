import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { OwnerType } from './owner';

export enum DiscountEligibilityType {
  Product = 'Product',
  Organization = 'Organization',
  Staff = 'Staff',
}

export class DiscountEligibility {
  type: DiscountEligibilityType;

  constructor(json: any, type: DiscountEligibilityType) {
    this.type = type;
  }

  public static getFactory(): Factory<DiscountEligibility> {
    return new (class implements Factory<DiscountEligibility> {
      make(json: any): DiscountEligibility {
        switch (json.type) {
          case DiscountEligibilityType.Product:
            return new ProductEligibility(json);
          case DiscountEligibilityType.Organization:
            return new OrganizationEligibility(json);
          case DiscountEligibilityType.Staff:
            return new StaffEligibility(json);
          default:
            throw new Error('Unrecognized DiscountEligibility type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('Discount eligabilities are never stored in IndexedDB.');
      }
    })();
  }
}

// Discount is only valid for a specific product.
export class ProductEligibility extends DiscountEligibility implements Deletable {
  deleted: boolean;
  productId: string;
  organizationId: string; // Denormalized for search.
  coversOwnerTypes: OwnerType[];
  constructor(json: any) {
    super(json, DiscountEligibilityType.Product);
    this.deleted = json.deleted;
    this.productId = json.productId;
    this.organizationId = json.organizationId;
    this.coversOwnerTypes = json.coversOwnerTypes ? json.coversOwnerTypes.map((t: any) => t as OwnerType) : [];
  }
}

// Discount is only valid for a specific organization.
export class OrganizationEligibility extends DiscountEligibility implements Deletable {
  deleted: boolean;
  organizationId: string;
  coversOwnerTypes: OwnerType[];
  constructor(json: any) {
    super(json, DiscountEligibilityType.Organization);
    this.deleted = json.deleted;
    this.organizationId = json.organizationId;
    this.coversOwnerTypes = json.coversOwnerTypes ? json.coversOwnerTypes.map((t: any) => t as OwnerType) : [];
  }
}

// Only staff can apply this discount. This can only be used in conjunction with other
// eligibility types.
export class StaffEligibility extends DiscountEligibility {
  constructor(json: any) {
    super(json, DiscountEligibilityType.Staff);
  }
}
