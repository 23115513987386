import { Currency } from '../enum/currency';
import { CountryCode } from '../enum/country-code';
import { LanguageCode } from '../enum/language-code';
import { I18nString } from './i18nstring';
import { DomainExtension } from '../enum/domain-extension';
import { Period } from '../enum/period';
import { SortOrder } from '../enum/sort';
import { Product, UserBeneficiaryVersion } from '..';
import { VehicleCountryCode } from '../enum/vehicle-country-code';
import { AbbriviatedCurrency } from '../enum/abbriviated-currency';

export class Utilities {
  // Decimals for currencies.
  static getScale(currency: Currency): number {
    switch (currency) {
      case Currency.SEK:
        return 2;
      case Currency.NOK:
        return 2;
      case Currency.EUR:
        return 2;
      default:
        throw new Error('Could not find decimals for currency ' + currency + '.');
    }
  }

  static getWeightAndUnit(gram: number, decimals: number = 0): { data: string; unit: string } {
    // Num is value in grams
    // 1000g = 1kg;
    // 1000000g = 1tonne

    if (gram >= 1000000) {
      return { data: (gram * 0.000001).toFixed(decimals), unit: 'Ton' };
    } else if (gram >= 1000) {
      return { data: (gram * 0.001).toFixed(decimals), unit: 'Kg' };
    } else {
      return { data: gram.toFixed(decimals), unit: 'g' };
    }
  }

  static getPercentage(num: number, decimals: number = 0) {
    return (num * 100).toFixed(decimals);
  }

  static getTaxCents(sum: number, tax: number, scale: number): number {
    return sum - (sum / Math.pow(10, scale) + tax); //Utilities.evenRound
  }

  static toDate(source: string) {
    typeof source === 'string' ? new Date(source) : undefined;
  }

  static formatNorwegianDate(date: string) {
    return new Date(date.substring(4, 8) + '-' + date.substring(2, 4) + '-' + date.substring(0, 2));
  }

  static formatToNorwegianDate(date: string) {
    return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4);
  }

  static formatDateWithAddedYear(date: string | number | Date, yearsToAdd: number) {
    // Create a copy of the original date
    const newDate = new Date(date);

    // Add the specified number of years
    newDate.setFullYear(newDate.getFullYear() + yearsToAdd);

    // Format the new date
    const formattedDate = newDate.toISOString().slice(0, 10);

    return formattedDate;
  }

  static toFormattedDate(date: string | number | Date, expanded: boolean = true) {
    const d = new Date(date);

    const yyyy = d.getFullYear().toString();
    const MM = this.toPrefixedTime(d.getMonth() + 1);
    const dd = this.toPrefixedTime(d.getDate());
    const hh = this.toPrefixedTime(d.getHours());
    const mm = this.toPrefixedTime(d.getMinutes());

    if (expanded) {
      var o = yyyy + '-' + MM + '-' + dd + 'T' + hh + ':' + mm;
    } else {
      var o = yyyy + '-' + MM + '-' + dd;
    }
    return o;
  }

  static toFormatDateOfBirth(date: string | number | Date, expanded: boolean = false) {
    const inputDate = date.toString();

    if (inputDate.length === 12 && expanded === false) {
      const yyyy = inputDate.slice(0, 4);
      const MM = inputDate.slice(4, 6);
      const dd = inputDate.slice(6, 8);

      return `${yyyy}-${MM}-${dd}`;
    } else if (inputDate.length === 12 && expanded === true) {
      const yyyy = inputDate.slice(0, 4);
      const MM = inputDate.slice(4, 6);
      const dd = inputDate.slice(6, 8);
      const XXXX = inputDate.slice(8);

      return `${yyyy}${MM}${dd}-${XXXX}`;
    } else if (inputDate.length === 8) {
      const dd = inputDate.slice(0, 2);
      const MM = inputDate.slice(2, 4);
      const yyyy = inputDate.slice(4);

      return `${yyyy}-${MM}-${dd}`;
    } else {
      return '';
    }
  }

  static toFormatNid(nid: string): string {
    const inputDate = nid.toString();

    if (inputDate.length === 12) {
      const yyyy = inputDate.slice(0, 4);
      const MM = inputDate.slice(4, 6);
      const dd = inputDate.slice(6, 8);
      const XXXX = inputDate.slice(8);

      return `${yyyy}${MM}${dd}${XXXX}`;
    } else if (inputDate.length === 13) {
      const yyyy = inputDate.slice(0, 4);
      const MM = inputDate.slice(4, 6);
      const dd = inputDate.slice(6, 8);
      const XXXX = inputDate.slice(10);

      return `${yyyy}${MM}${dd}${XXXX}`;
    } else {
      return nid;
    }
  }

  static sortEnumToTextConverter(sortEnum: SortOrder): string {
    switch (sortEnum) {
      case SortOrder.Ascending:
        return 'Stigande';
      case SortOrder.Descending:
        return 'Fallande';
      case SortOrder.Oldest:
        return 'Äldst';
      case SortOrder.Latest:
        return 'Senaste';
      default:
        return '';
    }
  }

  static convertCountryCodeToVehicleCountryCode(countryCode: CountryCode): VehicleCountryCode {
    switch (countryCode) {
      case CountryCode.SE:
        return VehicleCountryCode.SE;
      case CountryCode.NO:
        return VehicleCountryCode.NO;
      case CountryCode.DK:
        return VehicleCountryCode.DK;
      case CountryCode.FI:
        return VehicleCountryCode.FI;
      default:
        throw new Error('Country code not implemented.');
    }
  }

  static formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.indexOf('+') === -1) {
      return phoneNumber;
    } else {
      return phoneNumber.replace('+', '');
    }
  }

  static formatTruncatedCardNumberPreview(cardNumber: string): string {
    return '**** **** **** ' + cardNumber.substring(12, 16);
  }

  static toPrefixedTime(value: number): string {
    return value < 10 ? '0' + value.toString() : value.toString();
  }

  /**
   * @param digits Length of generated string, defaults to 4.
   * @param numbersOnly return includes only numbers.
   */
  static generateRandomString(digits: number = 4, numbersOnly: boolean = false) {
    if (digits < 4) {
      throw new Error('Minimum allowed digits is 4.');
    }

    let string = '';

    let set = 'ABCDEFGHJKLMNOPQRSTUVWXYZ' + 'abcdefghijkmnopqrstuvwxyz0123456789';
    let length = set.length + 1;

    if (numbersOnly) {
      set = '0123456789';
      length = set.length;
    }

    for (let i = 0; i < digits; i++) {
      const char = Math.floor(Math.random() * length);
      string += set.charAt(char);
    }

    return string;
  }

  getEnumValues<T extends { [key: number]: string | number }>(e: T): T[keyof T][] {
    return Object.keys(e).filter((value) => typeof value === 'string') as T[keyof T][];
  }

  getCurrencyByCountry(country: CountryCode): Currency {
    switch (country) {
      case CountryCode.SE:
        return Currency.SEK;
      case CountryCode.NO:
        return Currency.NOK;
      case CountryCode.DK:
        return Currency.DKK;
      case CountryCode.FI:
        return Currency.EUR;
      default:
        throw new Error('Currency not implemented.');
    }
  }

  getLanguageByCountry(country: CountryCode): LanguageCode {
    switch (country) {
      case CountryCode.SE:
        return LanguageCode.Swedish;
      case CountryCode.NO:
        return LanguageCode.Norwegian;
      case CountryCode.DK:
        return LanguageCode.Danish;
      case CountryCode.FI:
        return LanguageCode.Finnish;
      default:
        return LanguageCode.English;
    }
  }

  isFacebookInAppBrowser(): boolean {
    const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /FBAN|FBAV|FB_IAB|Instagram/.test(ua);
  }
  
  static i18nPeriodAndCurrency(period?: Period, currency?: Currency): string {
    switch (`${period}${currency}`) {
      case `${Period.Month}${Currency.NOK}`:
        return currency + ' / måned';
      case `${Period.QuarterYear}${Currency.NOK}`:
        return currency + ' / kvartal';
      case `${Period.HalfYear}${Currency.NOK}`:
        return currency + ' / halvår';
      case `${Period.Year}${Currency.NOK}`:
        return currency + ' / år';
      case `${Period.Month}${Currency.SEK}`:
        return currency + ' / månad';
      case `${Period.QuarterYear}${Currency.SEK}`:
        return currency + ' / kvartal';
      case `${Period.HalfYear}${Currency.SEK}`:
        return currency + ' / halvår';
      case `${Period.Year}${Currency.SEK}`:
        return currency + ' / år';
      default:
        return '';
    }
  }

  static i18nPeriod(period?: Period, currency?: Currency): string {
    switch (`${period}${currency}`) {
      case `${Period.Month}${Currency.NOK}`:
        return 'måned';
      case `${Period.QuarterYear}${Currency.NOK}`:
        return 'kvartal';
      case `${Period.HalfYear}${Currency.NOK}`:
        return 'halvår';
      case `${Period.Year}${Currency.NOK}`:
        return 'år';
      case `${Period.Month}${Currency.SEK}`:
        return 'månad';
      case `${Period.QuarterYear}${Currency.SEK}`:
        return 'kvartal';
      case `${Period.HalfYear}${Currency.SEK}`:
        return 'halvår';
      case `${Period.Year}${Currency.SEK}`:
        return 'år';
      default:
        return '';
    }
  }

  static isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static findPriceForFamily(parentProduct: Product, familyProduct: UserBeneficiaryVersion) {
    const ps = parentProduct.paymentSchedule;

    const isDiscounted = familyProduct?.costs.find((c) => c.schedule === ps)?.discount;

    if (isDiscounted) {
      return isDiscounted.price + ' ' + this.i18nPeriodAndCurrency(ps, parentProduct.currency);
    } else {
      return (
        familyProduct?.costs.find((c) => c.schedule === ps)?.price + ' ' +
        AbbriviatedCurrency.kr +
        ' / ' +
        this.i18nPeriod(ps, parentProduct.currency)
      );
    }
  }

  static countMonthsToAdd(period: Period): number {
    if (period === Period.Year) {
      return 12;
    } else if (period === Period.HalfYear) {
      return 6;
    } else if (period === Period.QuarterYear) {
      return 3;
    } else if (period === Period.Month) {
      return 1;
    }

    throw new Error('Unexpected period: ' + period);
  }

  /**
   * Parses an array of internationalized strings and returns the appropriate string for the specified language code.
   *
   * @param {I18nString[]} i18n - An array of internationalized strings containing language and corresponding values.
   * @param {string} languageCode - The language code to determine the appropriate internationalized string.
   * @returns {string} The internationalized string corresponding to the specified language code. If the language code is not found,
   * it falls back to 'en' (English) language. If 'en' is also not found, it returns the first available value. Throws an error if
   * the input array is empty or undefined.
   *
   * @throws {Error} Throws an error if the input i18n array is empty or undefined.
   *
   * @example
   * // Example usage of parseI18n method
   * const i18nStrings = [
   *   { language: 'en', value: 'Hello' },
   *   { language: 'fr', value: 'Bonjour' },
   *   { language: 'es', value: 'Hola' }
   * ];
   *
   * const result = parseI18n(i18nStrings, 'fr');
   * console.log(result); // Output: 'Bonjour'
   */
  static parseI18n(i18n: I18nString[], languageCode: LanguageCode): string {
    if (!i18n || i18n.length === 0) {
      throw new Error('i18n is empty');
    }
    let i = i18n.findIndex((i) => {
      return i.language === languageCode;
    });
    if (i < 0) {
      i = i18n.findIndex((i) => {
        return i.language === 'en';
      });
    }
    if (i < 0) {
      return i18n[0].value;
    } else {
      return i18n[i].value;
    }
  }

  /**
 * Checks if the provided value is an array where every item is an instance of I18nString.
 *
 * @param {any} arr - The value to be checked.
 * @returns {arr is I18nString[]} True if the value is an array of I18nString, false otherwise.

 */
  static isArrayI18n(arr: any): arr is I18nString[] {
    return Array.isArray(arr) && arr.every((item) => item instanceof I18nString);
  }

  /**
   * Retrieves the country code associated with the domain extension of a given URL.
   *
   * @static
   * @param {string} urlString - The URL string to extract the domain extension from.
   * @returns {CountryCode} - The associated country code based on the domain extension.
   * If the domain extension doesn't match any known codes, it defaults to `CountryCode.EN`.
   *
   * @example
   * const url = "https://www.example.se";
   * const code = Utilities.getCountryCodeFromUrl(url); // Returns: CountryCode.SE
   *
   * @throws {Error} - Throws an error if the provided string does not contain a valid domain extension.
   */
  static getCountryCodeFromUrl(urlString: string): CountryCode {
    const url = new URL(urlString);
    const parts = url.hostname.split('.');
    const extension: DomainExtension | undefined = parts[parts.length - 1] as DomainExtension;

    if (!extension) {
      throw new Error('String is not a valid URL.');
    }

    switch (extension) {
      case DomainExtension.SE:
        return CountryCode.SE;
      // case DomainExtension.NO:
      //     return CountryCode.NO;
      // case DomainExtension.DK:
      //   return CountryCode.DK;
      // case DomainExtension.FI:
      //   return CountryCode.FI;
      default:
        return CountryCode.NO;
    }
  }
}

declare global {
  interface Date {
    getWeek(): number;
    addDays(days: number): Date;
  }
}

// Returns the ISO week of the date.
Date.prototype.getWeek = function (): number {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};

Date.prototype.addDays = function (days: number): Date {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
