import { Identifiable } from '../../interface/identifiable';
import { Deletable } from '../../interface/deletable';
import { HasUserId } from '../../interface/has-user-id';
import { Factory } from '../../interface/factory';
import { CountryCode } from '../../enum/country-code';
import { Period, Period as ProductPeriod } from '../../enum/period';
import { HasTemplateId } from '../../interface/has-template-id';
import { Currency } from '../../enum/currency';
import { MothershipData } from './../mothership-data';
import { BeneficiaryType } from './beneficiary';
import { Owner } from '../owner';
import { PaymentSpecification } from '../payment-specification';
import {
  DiscountPercentageVoucher,
  DiscountSinglePeriodPercentageVoucher,
  DiscountSinglePeriodPriceCampaign,
} from '../discount';
import Decimal from 'decimal.js';

export class RenewalOffer {
  price: number; // Price including discount.
  constructor(json: any) {
    this.price = Number(json.price);
  }
}

export enum ProductStatus {
  Tentative = 'Tentative',
  Ongoing = 'Ongoing',
  Cancelled = 'Cancelled',
  Terminated = 'Terminated',
}

export class ProductParent implements Identifiable {
  id: string;
  start: Date;
  constructor(json: any) {
    this.id = json.id;
    this.start = new Date(json.start);
  }
}

export class Periods {
  price: string;
  start: Date;
  end: Date;
  templateVersion: number;
  duration: Period;
  paymentSchedule: Period;

  constructor(json: any) {
    this.price = json.price;
    this.start = new Date(json.start);
    this.end = new Date(json.end);
    this.templateVersion = json.templateVersion;
    this.duration = json.duration;
    this.paymentSchedule = json.paymentSchedule;
  }
}

export class Product implements Deletable, Identifiable, HasUserId, HasTemplateId {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  beneficiary: BeneficiaryType;
  aliasId?: number; // NOTE: Optional alias id for the product.

  countryCode: CountryCode; // Denormalized for db queries.
  issuingOrganizationIds: string[]; // NOTE: Lower index is higher in the hierarchy. Denormalized from template.

  templateId: string;
  templateVersion: number; // NOTE: The version number is 1 + the index in the array of the template.

  policyholder: Owner;
  parent?: ProductParent;

  beneficiaryId: string; // Note: The beneficiary here is person, so this will be a user id.

  currency: Currency; // Denormalized from template.
  price: number; // Price before discount. NOTE: Sent from the client as after discount.
  netPremium: number;
  period: ProductPeriod;
  paymentSchedule: ProductPeriod;
  discountId?: string; // NOTE: Optional preferred discount for the next payment.

  status: ProductStatus;

  start: Date;
  qualifyingTime: Date;
  end?: Date; // Do not renew after this date.

  periods: Periods[];

  paymentSpecifications: PaymentSpecification[];

  renewalOffer?: RenewalOffer;

  mothershipData?: MothershipData;

  created: Date;
  modified: Date;

  constructor(json: any, beneficiary: BeneficiaryType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.beneficiary = beneficiary;

    this.aliasId = Number(json.aliasId) || undefined;
    // this.sequence = Number(json.sequence);

    this.countryCode = json.countryCode as CountryCode;
    this.issuingOrganizationIds = json.issuingOrganizationIds ?? [];

    this.templateId = json.templateId;
    this.templateVersion = Number(json.templateVersion);

    this.policyholder = Owner.getFactory().make(json.policyholder);
    this.parent = json.parent ? new ProductParent(json.parent) : undefined;

    this.beneficiaryId = json.beneficiaryId;

    this.currency = json.currency as Currency;
    this.price = Number(json.price);
    this.netPremium = Number(json.netPremium);
    this.period = json.period as ProductPeriod;
    this.paymentSchedule = json.paymentSchedule as ProductPeriod;
    this.discountId = json.discountId ? json.discountId : undefined;

    this.status = json.status as ProductStatus;

    this.start = new Date(json.start);
    this.qualifyingTime = new Date(json.qualifyingTime);
    this.end = json.end ? new Date(json.end) : undefined;

    this.periods = json.periods ? json.periods.map((p: any) => new Periods(p)) : [];

    const psf = PaymentSpecification.getFactory();
    this.paymentSpecifications = json.paymentSpecifications
      ? json.paymentSpecifications.map((p: any) => psf.make(p))
      : [];

    this.renewalOffer = json.renewalOffer ? new RenewalOffer(json.renewalOffer) : undefined;

    this.mothershipData = json.mothershipData ? new MothershipData(json.mothershipData) : undefined;

    this.created = json.created ? new Date(json.created) : new Date();
    this.modified = json.modified ? new Date(json.modified) : new Date();
  }

  canStatusChange(): boolean {
    if (this.status === ProductStatus.Ongoing) {
      return true;
    }

    // Status of Cancelled products can be changed if the end date has not passed.
    if (this.status === ProductStatus.Cancelled && this?.end !== undefined) {
      if (this.end) {
        const now = new Date().getTime();
        const end = this.end.getTime();
        return now < end;
      }
    }

    return false;
  }

  hasExpired(): boolean {
    const now = new Date().getTime();
    const end = this.end?.getTime() ?? 0;
    return now > end;
  }

  hasNotExpired(): boolean {
    const now = new Date().getTime();
    const end = this.end?.getTime() ?? 0;
    return now < end;
  }

  isActive(): boolean {
    return (
      this.status === ProductStatus.Ongoing ||
      (this.status === ProductStatus.Cancelled && this.hasNotExpired()) ||
      (this.status === ProductStatus.Terminated && this.hasNotExpired())
    );
  }

  hasNotStarted(): boolean {
    const now = new Date().getTime();
    const start = this.start.getTime();
    return now < start;
  }

  getLastPayment(): PaymentSpecification {
    return this.paymentSpecifications[this.paymentSpecifications.length - 1];
  }

  // findPaymentSequence(payment: PaymentSpecification, parent?: Product): { start: Date; end: Date; sequence: number } {
  //   const paymentIssued = payment.from;
  //   let periodLength = this.getPeriodLength(this.period);
  //   let sequences = 0;
  //   let currentPeriodStart = new Date(this.start);
  //   let currentPeriodEnd = this.addPeriod(currentPeriodStart, periodLength);

  //   if (parent) {
  //     let parentPeriodStart = new Date(parent.start);

  //     const parentPeriodStartMonth = new Date(parent.start).getMonth();
  //     const parentPeriodStartDay = new Date(parent.start).getDate();

  //     const productPeriodStartMonth = new Date(this.start).getMonth();
  //     const productPeriodStartDay = new Date(this.start).getDate();

  //     while (parentPeriodStart < this.start) {
  //       parentPeriodStart = this.addPeriod(parentPeriodStart, this.getPeriodLength(parent.period));
  //     }
  //     if (parentPeriodStartMonth === productPeriodStartMonth && parentPeriodStartDay === productPeriodStartDay) {
  //       currentPeriodStart = parentPeriodStart;
  //     } else {
  //       currentPeriodStart = this.subtractPeriod(parentPeriodStart, this.getPeriodLength(parent.period));
  //     }
  //     currentPeriodEnd = this.addPeriod(currentPeriodStart, periodLength);
  //   }

  //   // Calculate sequences until the period containing the payment issued date
  //   while (currentPeriodStart <= paymentIssued) {
  //     // Stop if the period end surpasses the payment date or the product end date
  //     if (this.end && currentPeriodEnd >= this.end) {
  //       currentPeriodEnd = this.end;
  //       if (currentPeriodStart >= currentPeriodEnd) {
  //         currentPeriodStart = this.subtractPeriod(currentPeriodStart, this.getPeriodLength(this.period));
  //       }
  //       break;
  //     } else if (currentPeriodEnd > paymentIssued) {
  //       break;
  //     }
  //     sequences++;
  //     currentPeriodStart = currentPeriodEnd;
  //     currentPeriodEnd = this.addPeriod(currentPeriodStart, periodLength);
  //   }

  //   if (sequences === 0) {
  //     currentPeriodStart = this.start;
  //   }
  //   return {
  //     start: new Date(currentPeriodStart),
  //     end: new Date(currentPeriodEnd),
  //     sequence: sequences + 1, // +1 to account for the current period containing the payment date
  //   };
  // }

  calculatePrice(
    discount:
      | DiscountPercentageVoucher
      | DiscountSinglePeriodPercentageVoucher
      | DiscountSinglePeriodPriceCampaign
      | undefined,
    price: number,
    paymentSpecFrom: Date,
  ) {
    if (discount instanceof DiscountSinglePeriodPriceCampaign) {
      price = new Decimal(discount.price).toNumber();
      if (discount.expiry && discount.expiry.getTime() < paymentSpecFrom.getTime()) {
        return (price = this.price);
      }
    } else if (discount instanceof DiscountSinglePeriodPercentageVoucher) {
      price = new Decimal(price).mul(new Decimal(1).sub(discount.percent)).toNumber();

      if (discount.expiry && discount.expiry.getTime() < paymentSpecFrom.getTime()) {
        return (price = this.price);
      }
    } else if (discount instanceof DiscountPercentageVoucher) {
      price = new Decimal(price).mul(new Decimal(1).sub(discount.percent)).toNumber();
    }

    return price;
  }

  findProductSequence(
    product: Product,
    paymentSpec: PaymentSpecification,
    parent?: Product,
  ): { sequence: number; periodStart: Date; periodEnd: Date } | undefined {
    const periodLength = this.getPeriodLength(product.period);
    let sequence = 1;
    let periodStart = new Date(product.start);
    let periodEnd = this.addPeriod(periodStart, periodLength);

    // Parse product end date if it exists
    const productEnd = product.end ? new Date(product.end) : null;

    // Extract payment start date
    const paymentStart = new Date(paymentSpec.from);

    if (parent) {
      let parentPeriodStart = new Date(parent.start);
      const parentPeriodLength = this.getPeriodLength(parent.period);

      // Align the parent's periods with the child's start date
      while (parentPeriodStart < periodStart) {
        parentPeriodStart = this.addPeriod(parentPeriodStart, parentPeriodLength);
        sequence++;
      }

      // Check if the parent's period aligns with the product's start date
      if (
        parentPeriodStart.getMonth() === periodStart.getMonth() &&
        parentPeriodStart.getDate() === periodStart.getDate()
      ) {
        periodStart = parentPeriodStart;
      } else {
        periodEnd = parentPeriodStart;
      }
    }

    if (!productEnd) {
      // Product without an end date
      while (periodEnd <= paymentStart) {
        periodStart = periodEnd;
        periodEnd = this.addPeriod(periodStart, periodLength);
        sequence++;
      }
    } else {
      // Product with an end date
      while (periodEnd <= paymentStart && periodEnd <= productEnd) {
        periodStart = periodEnd;
        periodEnd = this.addPeriod(periodStart, periodLength);
        sequence++;

        // If the new periodEnd exceeds productEnd, set it to productEnd and exit
        if (periodEnd > productEnd) {
          periodEnd = productEnd;
          break;
        }
      }

      // If paymentStart is after productEnd, ensure periodEnd doesn't exceed productEnd
      if (periodEnd > productEnd) {
        periodEnd = productEnd;
      }
    }

    return { sequence, periodStart, periodEnd };
  }

  // Helper function to add periods to a date based on the payment schedule
  private addPeriod(date: Date, periodLength: { months: number }): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + periodLength.months);
    return newDate;
  }

  private subtractPeriod(date: Date, periodLength: { months: number }): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - periodLength.months);
    return newDate;
  }

  // Helper function to determine the length of periods
  private getPeriodLength(period: ProductPeriod): { months: number } {
    switch (period) {
      case ProductPeriod.Day:
        return { months: 0 }; // Not handling days for simplicity
      case ProductPeriod.Month:
        return { months: 1 };
      case ProductPeriod.QuarterYear:
        return { months: 3 };
      case ProductPeriod.HalfYear:
        return { months: 6 };
      case ProductPeriod.Year:
        return { months: 12 };
      default:
        throw new Error('Unsupported period type');
    }
  }

  public static getFactory(): Factory<Product> {
    return new (class implements Factory<Product> {
      make(json: any): Product {
        switch (json.beneficiary) {
          case BeneficiaryType.User:
            return new UserProduct(json);
          case BeneficiaryType.Object:
            return new ObjectProduct(json);
          default:
            throw new Error('Unrecognized product beneficiary (' + json.beneficiary + ').');
        }
      }

      getTableName(): string {
        return 'products';
      }
    })();
  }

  static getUrl(countryCode: CountryCode, productId?: string): string;
  static getUrl(countryCode: CountryCode, productId: string): string {
    return '/countries/' + countryCode + '/products' + (productId ? '/' + productId : '');
  }
}

export class UserProduct extends Product {
  constructor(json: any) {
    super(json, BeneficiaryType.User);
  }
}

export class ObjectProduct extends Product {
  constructor(json: any) {
    super(json, BeneficiaryType.Object);
  }
}
