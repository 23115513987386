import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { HasVehicleBrandId } from '../interface/has-vehicle-brand-id';

export enum VehicleModelType {
  PB = 'PB', // v1 Personbil (Car)
  HB = 'HB', // v1 Husbil (Camper)
  LLB = 'LLB', // v1 Lätt Lastbil (Light Truck)
  TL = 'TL', // v2+ Tung Lastbil (Heavy Truck)
  TB = 'TB', // v2+ Tung Buss (Heavy Bus)
  HV = 'HV', // v1 Husvagn (Caravan)
  LSL = 'LSL', // v1 Släpfordon (Light Trailer)
  MC = 'MC', // v1 Motorcykel (Motorbike)
  MB = 'MB', // v2+ Mopedbil (Moped Car)
  TSL = 'TSL', // v2+ Tungt släp (Heavy Trailer)
  EUM = 'EUM', // v2+ EU-moped (EU Moped)

  // VIKING GUARD TYPE
  // HOUSE = 'HOUSE', // House
  // CABIN = 'CABIN', // Cabin
  // FLAT = 'FLAT', // Flat
}

export class VehicleModel implements Deletable, Identifiable, HasVehicleBrandId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  type: VehicleModelType;
  vehicleBrandId: string;

  names: I18nString[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.type = json.type as VehicleModelType;
    this.vehicleBrandId = json.vehicleBrandId;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<VehicleModel> {
    return new (class implements Factory<VehicleModel> {
      make(json: any): VehicleModel {
        return new VehicleModel(json);
      }
      getTableName(): string {
        return 'vehicle_models';
      }
    })();
  }

  static getUrl(brandId: string, modelId?: string): string;
  static getUrl(brandId: string, modelId: string): string {
    return '/vehicle_brands/' + brandId + '/vehicle_models' + (modelId ? '/' + modelId : '');
  }
}
