import { environment } from '../../../../environments/environment';
import { Factory } from '../../interface/factory';
import { Currency } from '../../enum/currency';
import { Media } from '../media';
import { Period } from '../../enum/period';
import { I18nString } from '../i18nstring';
import { Duration } from '../duration';
import { SinglePeriodPriceCampaign } from '../discount-template';
import { OwnerType } from '../owner';
import { VehicleModelType } from '../vehicle-model';

export class Cost {
  price: number;
  period: Period;
  schedule: Period;
  // Note: The discount here should be enforced to be of a "campaign" variety.
  discount?: SinglePeriodPriceCampaign;
  netPremium: number;
  constructor(json: any) {
    this.price = Number(json.price);
    this.period = json.period as Period;
    this.schedule = json.schedule as Period;
    this.discount = json.discount ? new SinglePeriodPriceCampaign(json.discount) : undefined;
    this.netPremium = json.netPremium;
  }
}

export class Perk {
  titles: I18nString[];
  abouts: I18nString[];
  constructor(json: any) {
    this.titles = json.titles ? json.titles.map((js: any) => new I18nString(js)) : [];
    this.abouts = json.abouts ? json.abouts.map((js: any) => new I18nString(js)) : [];
  }
}

export class Include {
  titles: I18nString[];
  abouts: I18nString[];
  constructor(json: any) {
    this.titles = json.titles ? json.titles.map((js: any) => new I18nString(js)) : [];
    this.abouts = json.abouts ? json.abouts.map((js: any) => new I18nString(js)) : [];
  }
}

export class UserBeneficiaryVersion {
  coversObjectTypes: VehicleModelType[];
  coversPolicyholderTypes: OwnerType[];

  names: I18nString[];
  introductions: I18nString[];
  descriptions: I18nString[];
  media: Media[];

  currency: Currency;

  costs: Cost[];
  includes: Include[];
  perks: Perk[];

  url?: string;
  termsUrl?: string; // Fullständiga villkor.
  prePurchaseInformationUrl?: string; // Förköpsinformation.
  productSheetUrl?: string; // Produktblad.

  waitingDays?: number; // Product start cannot be before this number of days.
  waitingTime?: Duration; // NOTE: Waiting time starts from midnight for country.

  // The product will not be usable (though it has started, and the
  // user is paying for the product) until this number of days.
  qualifyingDays?: number;
  qualifyingTime?: Duration; // NOTE: Qualifying time starts from midnight for country.

  created: Date;

  constructor(json: any) {
    this.coversObjectTypes = json.coversObjectTypes ? json.coversObjectTypes.map((t: any) => t as VehicleModelType) : [];
    this.coversPolicyholderTypes = json.coversPolicyholderTypes
      ? json.coversPolicyholderTypes.map((t: any) => t as OwnerType)
      : [];

    this.names = json.names ? json.names.map((js: any) => new I18nString(js)) : [];
    this.introductions = json.introductions ? json.introductions.map((js: any) => new I18nString(js)) : [];
    this.descriptions = json.descriptions ? json.descriptions.map((js: any) => new I18nString(js)) : [];

    const mediaFactory: Factory<Media> = Media.getFactory();
    this.media = json.media ? json.media.map((mjson: any) => mediaFactory.make(mjson)) : [];

    this.currency = json.currency as Currency;

    this.costs = json.costs ? json.costs.map((cjson: any) => new Cost(cjson)) : [];
    this.includes = json.includes ? json.includes.map((ijson: any) => new Include(ijson)) : [];
    this.perks = json.perks ? json.perks.map((pjson: any) => new Perk(pjson)) : [];

    this.url = json.url ? json.url : undefined;
    this.termsUrl = json.termsUrl ? json.termsUrl : undefined;
    this.prePurchaseInformationUrl = json.prePurchaseInformationUrl ? json.prePurchaseInformationUrl : undefined;
    this.productSheetUrl = json.productSheetUrl ? json.productSheetUrl : undefined;

    this.waitingDays = Number(json.waitingDays);
    this.waitingTime = json.waitingTime !== undefined ? new Duration(json.waitingTime) : undefined;

    this.qualifyingDays = Number(json.qualifyingDays);
    this.qualifyingTime = json.qualifyingTime !== undefined ? new Duration(json.qualifyingTime) : undefined;

    this.created = new Date(json.created);
  }

  getMediaUrl(index: number = 0): string | undefined {
    return this.media[index] ? environment.blobUrl + '/files/' + this.media[index].filename : undefined;
  }

  findPriceForPeriod(period: Period): Cost | null {
    return this.costs.find((item) => item.period === period) ?? null; // Return null if the specific period is not found in the array
  }

  findMostCostEfficientPrice(): Cost | null {
    if (this.costs.length === 0) {
      return null; // Return null if the array is empty
    }

    // Sort array by ascending prices
    const items = this.costs.slice().sort((a, b) => a.price - b.price);

    let mostCostEfficientPrice: Cost = items[0];

    for (let i = 1; i < items.length; i++) {
      const currentItem = items[i];

      // Calculate the cost per unit period (e.g., cost per month, cost per half-year, etc.)
      const currentCostPerUnitPeriod = currentItem.price / this.getPeriodInMonths(currentItem);

      // Calculate the cost per unit period for the most cost-efficient price
      const mostEfficientCostPerUnitPeriod =
        mostCostEfficientPrice.price / this.getPeriodInMonths(mostCostEfficientPrice);

      // Compare the cost per unit period to determine if the current item is more cost-efficient
      if (currentCostPerUnitPeriod < mostEfficientCostPerUnitPeriod) {
        mostCostEfficientPrice = currentItem;
      }
    }

    return mostCostEfficientPrice;
  }

  // Helper function to convert periods to months for comparison
  getPeriodInMonths(item: Cost): number {
    switch (item.period) {
      case 'Year':
        return 12;
      case 'HalfYear':
        return 6;
      case 'QuarterYear':
        return 3;
      case 'Month':
        return 1;
      default:
        throw new Error(`Unsupported period: ${item.period}`);
    }
  }
}

export class ObjectBeneficiaryVersion {
  coversObjectTypes: VehicleModelType[];
  coversPolicyholderTypes: OwnerType[];

  names: I18nString[];
  introductions: I18nString[];
  descriptions: I18nString[];
  media: Media[];

  currency: Currency;

  costs: Cost[];
  includes: Include[];
  perks: Perk[];

  url?: string;
  termsUrl?: string; // Fullständiga villkor.
  prePurchaseInformationUrl?: string; // Förköpsinformation.
  productSheetUrl?: string; // Produktblad.

  waitingDays?: number; // Product start cannot be before this number of days.
  waitingTime?: Duration; // NOTE: Waiting time starts from midnight for country.

  // The product will not be usable (though it has started, and the
  // user is paying for the product) until this number of days.
  qualifyingDays?: number;
  qualifyingTime?: Duration; // NOTE: Qualifying time starts from midnight for country.

  created: Date;

  constructor(json: any) {
    this.coversObjectTypes = json.coversObjectTypes ? json.coversObjectTypes.map((t: any) => t as VehicleModelType) : [];
    this.coversPolicyholderTypes = json.coversPolicyholderTypes
      ? json.coversPolicyholderTypes.map((t: any) => t as OwnerType)
      : [];

    this.names = json.names ? json.names.map((js: any) => new I18nString(js)) : [];
    this.introductions = json.introductions ? json.introductions.map((js: any) => new I18nString(js)) : [];
    this.descriptions = json.descriptions ? json.descriptions.map((js: any) => new I18nString(js)) : [];

    const mediaFactory: Factory<Media> = Media.getFactory();
    this.media = json.media ? json.media.map((mjson: any) => mediaFactory.make(mjson)) : [];

    this.currency = json.currency as Currency;

    this.costs = json.costs ? json.costs.map((cjson: any) => new Cost(cjson)) : [];
    this.includes = json.includes ? json.includes.map((ijson: any) => new Include(ijson)) : [];
    this.perks = json.perks ? json.perks.map((pjson: any) => new Perk(pjson)) : [];

    this.url = json.url ? json.url : undefined;
    this.termsUrl = json.termsUrl ? json.termsUrl : undefined;
    this.prePurchaseInformationUrl = json.prePurchaseInformationUrl ? json.prePurchaseInformationUrl : undefined;
    this.productSheetUrl = json.productSheetUrl ? json.productSheetUrl : undefined;

    this.waitingDays = Number(json.waitingDays);
    this.waitingTime = json.waitingTime !== undefined ? new Duration(json.waitingTime) : undefined;

    this.qualifyingDays = Number(json.qualifyingDays);
    this.qualifyingTime = json.qualifyingTime !== undefined ? new Duration(json.qualifyingTime) : undefined;

    this.created = new Date(json.created);
  }

  getMediaUrl(index: number = 0): string | undefined {
    return this.media[index] ? environment.blobUrl + '/files/' + this.media[index].filename : undefined;
  }

  findPriceForPeriod(period: Period): Cost | null {
    return this.costs.find((item) => item.period === period) ?? null; // Return null if the specific period is not found in the array
  }

  findMostCostEfficientPrice(): Cost | null {
    if (this.costs.length === 0) {
      return null; // Return null if the array is empty
    }

    // Sort array by ascending prices
    const items = this.costs.slice().sort((a, b) => a.price - b.price);

    let mostCostEfficientPrice: Cost = items[0];

    for (let i = 1; i < items.length; i++) {
      const currentItem = items[i];

      // Calculate the cost per unit period (e.g., cost per month, cost per half-year, etc.)
      const currentCostPerUnitPeriod = currentItem.price / this.getPeriodInMonths(currentItem);

      // Calculate the cost per unit period for the most cost-efficient price
      const mostEfficientCostPerUnitPeriod =
        mostCostEfficientPrice.price / this.getPeriodInMonths(mostCostEfficientPrice);

      // Compare the cost per unit period to determine if the current item is more cost-efficient
      if (currentCostPerUnitPeriod < mostEfficientCostPerUnitPeriod) {
        mostCostEfficientPrice = currentItem;
      }
    }

    return mostCostEfficientPrice;
  }

  // Helper function to convert periods to months for comparison
  getPeriodInMonths(item: Cost): number {
    switch (item.period) {
      case 'Year':
        return 12;
      case 'HalfYear':
        return 6;
      case 'QuarterYear':
        return 3;
      case 'Month':
        return 1;
      default:
        throw new Error(`Unsupported period: ${item.period}`);
    }
  }
}
