import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorService } from './shared/error/error.service';
import { RegionService } from './services/region/region.service';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CacheService } from './services';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytic.service';
import { CookiebotService } from './services/cookiebot/cookiebot.service';
import { UtmService } from './services/utm/utm.service';
import { FacebookPixelService } from './services/facebook-pixel/facebook-pixel.service';
import { FathomService } from './services/fathom/fathom.service';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private errorSubscription$?: Subscription;
  environment = environment;
  error: boolean;
  loading: boolean;

  constructor(
    private errorService: ErrorService,
    private regionService: RegionService,
    private meta: Meta,
    private route: ActivatedRoute,
    private cache: CacheService,
    private router: Router,
    private dom: Renderer2,
    private ga4: GoogleAnalyticsService,
    private cookiebot: CookiebotService,
    private fbPixel: FacebookPixelService,
    private utm: UtmService,
    private fathom: FathomService,
  ) {
    this.error = false;
    this.loading = true;
  }

  async ngOnInit() {
    await this.regionService.init();

    // Redirect to app if in Facebook in-app browser
    // const utilities = new Utilities();
    // const isInAppBrowser = utilities.isFacebookInAppBrowser();

    // if (isInAppBrowser) {
    //   this.router.navigate(['/app']);
    // }

    this.errorSubscription$ = this.errorService.error.subscribe((e) => {
      this.error = e.active;
    });

    this.route.fragment.subscribe(async (fragment) => {
      if (fragment?.toLowerCase() === Fragment.Reset) {
        await this.onClear();
      }
    });

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        await this.utm.fetchUtmParameters();
      }
    });

    const description = this.regionService.getDescriptionForRegion();
    this.meta.updateTag({ name: 'description', content: description });

    await this.cache.countryHasPolled();

    this.loading = false;
  }

  async ngAfterViewInit(): Promise<void> {
    await this.loadFathom();
    await this.loadScripts();
  }

  ngOnDestroy(): void {
    this.errorService.clearError();
    if (this.errorSubscription$) {
      this.errorSubscription$.unsubscribe();
    }
  }

  private async loadFathom() {
    const fathomId = await this.fathom.getFathomId();

    const script = this.dom.createElement('script');
    script.src = 'https://cdn.usefathom.com/script.js';
    script.setAttribute('data-spa', 'auto');
    script.setAttribute('data-site', fathomId);
    script.defer = true;

    this.dom.appendChild(document.head, script);
  }

  private async loadScripts() {
    // Load Cookiebot script
    const cookiebotScript = this.dom.createElement('script');
    const cookiebotId = await this.cookiebot.getCookiebotId();

    cookiebotScript.id = 'Cookiebot';
    cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
    cookiebotScript.setAttribute('data-cbid', cookiebotId);
    cookiebotScript.setAttribute('type', 'text/javascript');
    cookiebotScript.setAttribute('async', true);

    this.dom.appendChild(document.head, cookiebotScript);

    // Wait for Cookiebot to be loaded
    cookiebotScript.onload = async () => {
      await this.insertGAScript();
      await this.insertFacebookPixelScript();
    };
  }

  private async insertFacebookPixelScript() {
    const script = this.dom.createElement('script');

    script.setAttribute('data-cookieconsent', 'ignore');

    const fbPixelId = await this.fbPixel.getFacebookPixelId();
    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('consent', 'revoke');
      fbq('init', '${fbPixelId}');
      fbq('track', 'PageView');
      window.addEventListener('CookiebotOnConsentReady',function(e){
      fbq('consent',Cookiebot.consent.marketing?'grant':'revoke')},!1)
    `;

    this.dom.appendChild(document.head, script);
  }

  private async insertGAScript() {
    const script = this.dom.createElement('script');
    script.setAttribute('data-cookieconsent', 'marketing, preferences, statistics');
    script.type = 'text/plain';
    script.async = true;

    const ga4Id = this.ga4.getGA4ClientId();
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Id}`;

    this.dom.appendChild(document.head, script);

    const inlineScript = this.dom.createElement('script');
    inlineScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga4Id}');

      // Google Consent Mode configuration
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 2000
      });

      function configureGA(consentDetails) {
        gtag('consent', 'update', {
          'ad_storage': consentDetails.marketing ? 'granted' : 'denied',
          'analytics_storage': consentDetails.analytics ? 'granted' : 'denied',
          'functionality_storage': consentDetails.preferences ? 'granted' : 'denied',
          'personalization_storage': consentDetails.personalization ? 'granted' : 'denied',
          'security_storage': consentDetails.security ? 'granted' : 'denied'
        });
      }

      // Listen for consent changes
      window.addEventListener('cookieconsent:update', function(e) {
        const consent = e.detail || {};
        configureGA(consent);
      });

      // Check initial consent status
      if (window.Cookiebot && Cookiebot.consents) {
        const initialConsent = Cookiebot.consents;
        configureGA(initialConsent);
      }
    `;

    this.dom.appendChild(document.head, inlineScript);
  }

  async onClear() {
    this.cache.resetCache();
    await this.router.navigate(['/home']);

    setTimeout(async () => {
      window.location.reload();
    }, 100);
  }
}

enum Fragment {
  Reset = 'reset',
}
