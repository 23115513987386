import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheService } from './services/cache/cache.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorModule } from './shared/error/error.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { RegionService } from './services/region/region.service';
import { SjekkpunktFormComponent } from './sjekkpunkt/sjekkpunkt-form/sjekkpunkt-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytic.service';
import { provideLottieOptions } from 'ngx-lottie';
  // Import all the languages you need (you can add any language you want i.e: '../locales/en-GB', '/locales/en-US', ... ).
    // In this example, I will use 'es' for Spanish and 'fr' for French:
    import myLocaleSv from '@angular/common/locales/sv'
    import myLocaleNo from '@angular/common/locales/no'

    import {registerLocaleData} from '@angular/common';

    registerLocaleData(myLocaleSv);
    registerLocaleData(myLocaleNo);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeApp(translationService: TranslateService, regionService: RegionService) {
  return () => translationService.use(regionService.getClientLanguage()).toPromise();
}

@NgModule({
  declarations: [AppComponent, SjekkpunktFormComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ErrorModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CacheService,
    GoogleAnalyticsService,
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslateService, RegionService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
